@use 'node_modules/@sparbanken-syd/sparbanken-syd-theme/theme' as spb;

html {
  height: 100%;
  margin: 0;
  width: 100%;
}

body {
  font-family: sans-serif;
  height: 100%;
  margin: 0 auto;
  width: 100%;
}

@include spb.set-typography;


.spb-holder {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.spb-filler {
  flex-grow: 1;
}

/* Plain is just a holder for content on pages w/o hero images. */
.spb-plain-content {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  max-width: 1193px;
  width: 95vw;
}
